import { restructureResponseForCheckerColumnFilter } from '../../components/Shared/ColumnLevelModal/Utils/ColumnLevelhandleUtils';
import { restructureResponseForCheckerTable } from '../../utils/commonMethods/generalMethods';
import { getPaginatedChunkData, getReasonStatusMapping } from '../../utils/commonUtil';
import { AC_FAMILY_MAPPING } from '../../utils/constants';
import {
  CHECKER_DATA_ERROR, CHECKER_DETAILS_INITIAL_STATE, FIN_HISTORY_EROR, UPDATE_FIN_STATUS_EROR,
} from './constants';

export const checkerDetailsDataTableReducer = (
  state: CheckerDetailsTableStateType,
  action: CheckerDetailsTableAction,
) => {
  const calculatedPageNum = action.payload.paginationData.page;
  const prevPage = state.paginationData.page;
  const chunkData = getPaginatedChunkData(
    calculatedPageNum,
    action.payload.checkerDetailsData.tableData,
    action.payload.paginationData.rowPerPage,
    prevPage,
  );
  return ({
    ...state,
    paginationData: {
      start: action.payload.paginationData.start,
      end: action.payload.paginationData.end,
      rowPerPage: action.payload.paginationData.rowPerPage,
      page: action.payload.paginationData.page,
      selectedChunkData: chunkData,
    },
  });
};

export const updateCheckerFilterSelectionReducer = (state: CheckerDetailsTableStateType, action: UpdateCheckerFilterSelectionAction) => {
  return ({
    ...state,
    checkerDetailFiltersSelected: action.payload,
  });
};

export const updateCheckerFiltersOptionsReducer = (state: CheckerDetailsTableStateType, action: UpdateCheckerFiltersOptionsAction) => {
  return ({
    ...state,
    fleetDetailFilterOptions: action.payload,
  });
};

export const toggleReasonDetailPopup = (state: CheckerDetailsTableStateType, action: ToggleReasonDetail) => {
  return ({
    ...state,
    showReasonDetail: action.payload,
  });
};
export const toggleFilterModelPopup = (state: CheckerDetailsTableStateType, action: ToggleReasonDetail) => {
  return ({
    ...state,
    showFilterPanel: action.payload,
  });
};
export const setCheckerDetInitialLoad = (state: CheckerDetailsTableStateType, action: CheckerDetailsTableAction) => {
  return {
    ...state,
    checkerDetailsInitialLoad: action.payload.checkerDetailsInitialLoad,
  };
};
export const ColumnLevelPending = (
  state: CheckerDetailsTableStateType,
) => ({
  ...state,
  isColumnDataLoading: true,
});

export const ColumnLevelRejected = (state: CheckerDetailsTableStateType) => {
  return {
    ...state,
    isColumnDataLoading: false,
    errorMessage: CHECKER_DATA_ERROR,
    FilterData: CHECKER_DETAILS_INITIAL_STATE.FilterData,
  };
};

export const ColumnLevelFullfilled = (state: CheckerDetailsTableStateType, action: CheckerDetailsTableAction) => {
  const reasonStatusMapping = getReasonStatusMapping();
  return {
    ...state,
    isColumnDataLoading: false,
    FilterData: restructureResponseForCheckerColumnFilter(restructureResponseForCheckerTable(action.payload.result, reasonStatusMapping).tableData),
  };
};

export const ColumnFilterStateUpdate = (state: CheckerDetailsTableStateType, action: CheckerDetailsTableAction) => {
  return {
    ...state,
    isColumnDataLoading: false,
    FilterState: action.payload.records,
  };
};

export const ColumnFilterPayloadUpdate = (state: CheckerDetailsTableStateType, action: CheckerDetailsTableAction) => {
  return {
    ...state,
    isColumnDataLoading: false,
    FilterPayload: action.payload.records,
  };
};
export const setCheckerDetailOperatorChangeReducer = (state: CheckerDetailsTableStateType, action: CheckerDetailsTableAction) => {
  return {
    ...state,
    ifOperatorChanged: action.payload?.ifOperatorChanged,
    ifAircraftTypeChanged: action.payload?.ifAircraftTypeChanged,
    ifAircraftSubTypeChanged: action.payload?.ifAircraftSubTypeChanged,
  };
};
export const setFleetDetailFilterrReducer = (state: CheckerDetailsTableStateType, action: CheckerDetailsTableAction) => {
  return {
    ...state,
    fleet_detail_filter_selected: action?.payload?.fleet_detail_filter_selected,
  };
};

export const setFleetDetailSelectedFinReducer = (state: CheckerDetailsTableStateType, action: SelectedFinType) => {
  return {
    ...state,
    selectedFin: action?.payload,
  };
};

export const updateBottomDrawerStateReducer = (
  state: CheckerDetailsTableStateType,
  action: FinStatusDrawerAction,
) => ({
  ...state,
  isBottomDrawerClose: action.payload,
});

export const updateSelectAllBottomDrawerStateReducer = (
  state: CheckerDetailsTableStateType,
  action: FinStatusDrawerAction,
) => ({
  ...state,
  isSelectAllFin: action.payload,
});

export const clearFleetDetailSelectedFinReducer = (
  state: CheckerDetailsTableStateType,
  action: FinStatusDrawerAction,
) => ({
  ...state,
  isClearSelection: action.payload,
});

export const updateSetFinEditModalState = (
  state: CheckerDetailsTableStateType,
  action: FinStatusDrawerAction,
) => ({
  ...state,
  finEditModalState: action.payload,
});

// loading the fin status history extra reducers
export const loadFinStatusHistoryPending = (
  state: CheckerDetailsTableStateType,
) => ({
  ...state,
  isFinStatusHistoryLoading: true,
});

export const loadFinStatusHistoryRejected = (state: CheckerDetailsTableStateType) => {
  return {
    ...state,
    isFinStatusHistoryLoading: false,
    errorMessage: FIN_HISTORY_EROR,
  };
};

export const loadFinStatusHistoryFullfilled = (state: CheckerDetailsTableStateType, action: CheckerDetailsHistoryAction) => {
  return {
    ...state,
    isFinStatusHistoryLoading: false,
    finStatusHistory: action.payload.result,
  };
};

// loading the fin status history extra reducers
export const updateFinStatusPending = (
  state: CheckerDetailsTableStateType,
) => ({
  ...state,
  isFinStatusUpdateLoading: true,
});

export const updateFinStatusRejected = (state: CheckerDetailsTableStateType) => {
  return {
    ...state,
    isFinStatusUpdateLoading: false,
    errorMessage: UPDATE_FIN_STATUS_EROR,
  };
};

export const updateFinStatusFullfilled = (state: CheckerDetailsTableStateType) => {
  return {
    ...state,
    isFinStatusUpdateLoading: false,
    finEditModalState: false,
  };
};

export const updateFinChangesStateReducer = (
  state: CheckerDetailsTableStateType,
  action: SelectedFinType,
) => ({
  ...state,
  finChangesState: action.payload,
});

export const updateAcFamilyList = (state: CheckerDetailsTableStateType, action: AcFamilyOptions) => {
  const updatedRecords = action.payload?.AcFamily?.filter((record: string) => {
    return record in AC_FAMILY_MAPPING;
  });
  const acFamilyResp = updatedRecords?.map((item:string) => {
    const acFamily = AC_FAMILY_MAPPING[item];
    return { label: acFamily, value: item };
  });
  if (action.payload.meta?.arg === 'CheckerDetailsOperatorChanged') {
    return {
      ...state,
      checkerDetailsFilterOptions: {
        ...state.checkerDetailsFilterOptions,
        ac_family: acFamilyResp,
      },
    };
  }
  return {
    ...state,
    fleetDetailFilterOptions: { ...state.fleetDetailFilterOptions, ac_family: acFamilyResp },
  };
};

export const updateAircraftTypeList = (state: CheckerDetailsTableStateType, action: AcTypeOptions) => {
  if (action.payload.meta?.arg === 'CheckerDetailsOperatorChanged') {
    return {
      ...state,
      checkerDetailsFilterOptions: {
        ...state.checkerDetailsFilterOptions,
        ac_type: action.payload.AcType,
      },
    };
  }
  return {
    ...state,
    fleetDetailFilterOptions: {
      ...state.fleetDetailFilterOptions,
      ac_type: action.payload.AcType,
    },
  };
};

export const updateAircraftSubTypeList = (state: CheckerDetailsTableStateType, action: AcSubTypeOptions) => {
  if (action.payload.meta?.arg === 'CheckerDetailsOperatorChanged') {
    return {
      ...state,
      checkerDetailsFilterOptions: {
        ...state.checkerDetailsFilterOptions,
        ac_sub_type: action.payload.AcSubType,
      },
    };
  }
  return {
    ...state,
    fleetDetailFilterOptions: {
      ...state.fleetDetailFilterOptions,
      ac_sub_type: action.payload.AcSubType,
    },
  };
};

export const updateMsnList = (state: CheckerDetailsTableStateType, action: IdAircraftOptions) => {
  if (action.payload.meta?.arg === 'CheckerDetailsOperatorChanged') {
    return {
      ...state,
      checkerDetailsFilterOptions: {
        ...state.checkerDetailsFilterOptions,
        id_aircraft_registration: action.payload.IdAircraft,
      },
    };
  }
  return {
    ...state,
    fleetDetailFilterOptions: {
      ...state.fleetDetailFilterOptions,
      id_aircraft_registration: action.payload.IdAircraft,
    },
  };
};

export const setCurrentPopupDetailsReducer = (state: CheckerDetailsTableStateType, action: PopupDetailsType) => {
  return {
    ...state,
    currentPopupDetails: action.payload,
  };
};
