import { createSlice } from '@reduxjs/toolkit';
import {
  CHECKER_DETAILS_INITIAL_STATE,
  CHECKER_DETAILS_SLICE,
} from './constants';
import { checkerDetailsDataPaginationReducer } from './checkerDetailsPaginationReducer';
import {
  checkerDetailsDataTableReducer,
  toggleReasonDetailPopup,
  updateCheckerFilterSelectionReducer,
  updateCheckerFiltersOptionsReducer,
  setCheckerDetInitialLoad,
  ColumnLevelFullfilled,
  ColumnFilterStateUpdate,
  ColumnFilterPayloadUpdate,
  ColumnLevelPending,
  ColumnLevelRejected,
  setCheckerDetailOperatorChangeReducer,
  setFleetDetailFilterrReducer,
  setFleetDetailSelectedFinReducer,
  updateBottomDrawerStateReducer,
  updateSelectAllBottomDrawerStateReducer,
  updateSetFinEditModalState,
  loadFinStatusHistoryPending,
  loadFinStatusHistoryRejected,
  loadFinStatusHistoryFullfilled,
  updateFinStatusFullfilled,
  updateFinStatusPending,
  updateFinStatusRejected,
  updateFinChangesStateReducer,
  clearFleetDetailSelectedFinReducer,
  updateAcFamilyList,
  updateAircraftTypeList,
  updateAircraftSubTypeList,
  updateMsnList,
  setCurrentPopupDetailsReducer,
  toggleFilterModelPopup,
} from './checkerDetailsReducer';
import {
  loadCheckerDetails, loadCheckerDetailsFilterData, loadCheckerFinHistoryDetails, loadCheckerFleetFinAndAtaDetails, updateCheckerFinStatusDetails,
} from './checkerDetailsThunk';
import {
  setCheckerDetailsTableDataFulfilled,
  setCheckerDetailsTableDataPending,
  setCheckerDetailsTableDataRejected,
  loadCheckerFleetFinAndAtaDetailsFulfilled,
  loadCheckerFleetFinAndAtaDetailsPending,
  loadCheckerFleetFinAndAtaDetailsRejected,
} from './checkerDetailsExtraReducers';

export const checkerDetailsSlice = createSlice({
  name: CHECKER_DETAILS_SLICE,
  initialState: CHECKER_DETAILS_INITIAL_STATE,
  reducers: {
    updateCheckerDetailsDataTable: checkerDetailsDataTableReducer,
    updateCheckerDetailsDataPagination: checkerDetailsDataPaginationReducer,
    setCheckerDetailsTableData: setCheckerDetailsTableDataFulfilled,
    toggleReasonDetail: toggleReasonDetailPopup,
    updateCheckerFilterSelection: updateCheckerFilterSelectionReducer,
    updateCheckerFiltersOptions: updateCheckerFiltersOptionsReducer,
    setCheckerDetailsInitialLoad: setCheckerDetInitialLoad,
    updateCheckerDetailsColumnLevelFilter: ColumnLevelFullfilled,
    updateCheckerDetailsColumnFilterState: ColumnFilterStateUpdate,
    updateCheckerDetailsColumnFilterPayload: ColumnFilterPayloadUpdate,
    setFleetDetailFilter: setFleetDetailFilterrReducer,
    setCheckerDetailOperatorChanged: setCheckerDetailOperatorChangeReducer,
    setFleetDetailSelectedFin: setFleetDetailSelectedFinReducer,
    setFinBottomDrawerStatus: updateBottomDrawerStateReducer,
    updateSelectAllBottomDrawerStatus: updateSelectAllBottomDrawerStateReducer,
    setFinEditModalState: updateSetFinEditModalState,
    setFinChangesStateReducer: updateFinChangesStateReducer,
    clearFleetDetailSelectedFin: clearFleetDetailSelectedFinReducer,
    setAcFamilyOptions: updateAcFamilyList,
    setAcTypeOptions: updateAircraftTypeList,
    setAcSubTypeOptions: updateAircraftSubTypeList,
    setMsnOptions: updateMsnList,
    setCurrentPopupDetails: setCurrentPopupDetailsReducer,
    toggleFilterModel: toggleFilterModelPopup,
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadCheckerDetails().fulfilled, setCheckerDetailsTableDataFulfilled)
      .addCase(loadCheckerDetails().pending, setCheckerDetailsTableDataPending)
      .addCase(loadCheckerDetails().rejected, setCheckerDetailsTableDataRejected)
      .addCase(loadCheckerDetailsFilterData().pending, ColumnLevelPending)
      .addCase(loadCheckerDetailsFilterData().rejected, ColumnLevelRejected)
      .addCase(loadCheckerDetailsFilterData().fulfilled, ColumnLevelFullfilled)
      .addCase(loadCheckerFinHistoryDetails().pending, loadFinStatusHistoryPending)
      .addCase(loadCheckerFinHistoryDetails().rejected, loadFinStatusHistoryRejected)
      .addCase(loadCheckerFinHistoryDetails().fulfilled, loadFinStatusHistoryFullfilled)
      .addCase(updateCheckerFinStatusDetails().pending, updateFinStatusPending)
      .addCase(updateCheckerFinStatusDetails().rejected, updateFinStatusRejected)
      .addCase(updateCheckerFinStatusDetails().fulfilled, updateFinStatusFullfilled)
      .addCase(loadCheckerFleetFinAndAtaDetails().fulfilled, loadCheckerFleetFinAndAtaDetailsFulfilled)
      .addCase(loadCheckerFleetFinAndAtaDetails().pending, loadCheckerFleetFinAndAtaDetailsPending)
      .addCase(loadCheckerFleetFinAndAtaDetails().rejected, loadCheckerFleetFinAndAtaDetailsRejected);
  },
});
export const {
  updateCheckerDetailsDataTable,
  updateCheckerDetailsDataPagination,
  setCheckerDetailsTableData,
  toggleReasonDetail,
  updateCheckerFilterSelection,
  updateCheckerFiltersOptions,
  setCheckerDetailsInitialLoad,
  updateCheckerDetailsColumnLevelFilter,
  updateCheckerDetailsColumnFilterState,
  updateCheckerDetailsColumnFilterPayload,
  setFleetDetailFilter,
  setCheckerDetailOperatorChanged,
  setFleetDetailSelectedFin,
  setFinBottomDrawerStatus,
  updateSelectAllBottomDrawerStatus,
  setFinEditModalState,
  setFinChangesStateReducer,
  clearFleetDetailSelectedFin,
  setAcFamilyOptions,
  setAcTypeOptions,
  setAcSubTypeOptions,
  setMsnOptions,
  setCurrentPopupDetails,
  toggleFilterModel,
} = checkerDetailsSlice.actions;
export default checkerDetailsSlice;
